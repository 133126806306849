@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-Bold.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-BlackItalic.ttf") format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-Italic.ttf") format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-ExtraBold.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-LightItalic.ttf") format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-ExtraLightItalic.ttf") format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-ExtraBoldItalic.ttf") format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-ExtraLight.ttf") format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-BoldItalic.ttf") format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-MediumItalic.ttf") format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("../fonts/source-code-pro/SourceCodePro-SemiBoldItalic.ttf") format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

