@font-face {
    font-family: 'Hind Guntur';
    src: local('Hind Guntur Medium'), local('HindGuntur-Medium'),
        url("../fonts/hind-guntur/HindGuntur-Medium.woff2") format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind Guntur';
    src: local('Hind Guntur Light'), local('HindGuntur-Light'),
        url("../fonts/hind-guntur/HindGuntur-Light.woff2") format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind Guntur';
    src: local('Hind Guntur Bold'), local('HindGuntur-Bold'),
        url("../fonts/hind-guntur/HindGuntur-Bold.woff2") format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind Guntur';
    src: local('Hind Guntur SemiBold'), local('HindGuntur-SemiBold'),
        url("../fonts/hind-guntur/HindGuntur-SemiBold.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind Guntur';
    src: local('Hind Guntur Regular'), local('HindGuntur-Regular'),
        url("../fonts/hind-guntur/HindGuntur-Regular.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

