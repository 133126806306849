@font-face {
    font-family: 'Lato';
    src: local('Lato Italic'), local('Lato-Italic'),
        url("../fonts/lato/Lato-Italic.woff2") format('woff2'),
        url("../fonts/lato/Lato-Italic.woff") format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato Black'), local('Lato-Black'),
        url("../fonts/lato/Lato-Black.woff2") format('woff2'),
        url("../fonts/lato/Lato-Black.woff") format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato Regular'), local('Lato-Regular'),
        url("../fonts/lato/Lato-Regular.woff2") format('woff2'),
        url("../fonts/lato/Lato-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato Bold'), local('Lato-Bold'),
        url("../fonts/lato/Lato-Bold.woff2") format('woff2'),
        url("../fonts/lato/Lato-Bold.woff") format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-Light'),
        url("../fonts/lato/Lato-Light.woff2") format('woff2'),
        url("../fonts/lato/Lato-Light.woff") format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Hairline';
    src: local('Lato-HairlineItalic'),
        url("../fonts/lato/Lato-HairlineItalic.woff2") format('woff2'),
        url("../fonts/lato/Lato-HairlineItalic.woff") format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
        url("../fonts/lato/Lato-BoldItalic.woff2") format('woff2'),
        url("../fonts/lato/Lato-BoldItalic.woff") format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lato Hairline';
    src: local('Lato-Hairline'),
        url("../fonts/lato/Lato-Hairline.woff2") format('woff2'),
        url("../fonts/lato/Lato-Hairline.woff") format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-LightItalic'),
        url("../fonts/lato/Lato-LightItalic.woff2") format('woff2'),
        url("../fonts/lato/Lato-LightItalic.woff") format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato Black Italic'), local('Lato-BlackItalic'),
        url("../fonts/lato/Lato-BlackItalic.woff2") format('woff2'),
        url("../fonts/lato/Lato-BlackItalic.woff") format('woff');
    font-weight: 900;
    font-style: italic;
}

