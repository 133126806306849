.default main {
  margin-left: var(--space-x-large);
  margin-right: var(--space-x-large);

  @media(max-width: 120ch) {
    margin-left: var(--space-large);
    margin-right: var(--space-large);
  }

  @media(max-width: 80ch) {
    margin-left: var(--space-medium);
    margin-right: var(--space-medium);
  }
}

.default header {
  h1 {
    font-size: calc(2em + 4vw);
    max-width: 9em;
    text-decoration: underline;
    text-decoration-color: var(--green-700);
  }

  p {
    font-family: var(--body-font);
    font-size: var(--type-medium);
    line-height: 1.2;
    max-width: 65ch;

    &:not(:first-child) {
      margin-top: var(--space-medium);
    }
  }

  .binary-solo {
    text-decoration: none;
    color: var(--yellow-700);

    &:hover {
      text-decoration: underline;
    }
  }
}

.home .logo {
  position: absolute;
  height: calc(3em + 8vw);
  width: auto;
  top: 1em;
  right: 2em;

  @media(max-width: 60ch) {
    display: none;
  }
}

ul.posts {
  margin-top: var(--space-large);
  display: grid;
  grid-gap: var(--space-medium);
  grid-template-columns: repeat(auto-fill, minmax(32ch, 1fr));
}

ui-tile {
  display: flex;
  flex-direction: column;
  border: solid 2px var(--magenta-700);
  border-radius: 6px;
  height: 100%;
  padding: var(--space-small) var(--space-medium);
  box-sizing: border-box;

  h3 {
    a {
      color: var(--magenta-800);
      text-decoration: none;
      line-height: 1.1;

      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--green-700) !important;
      }
    }
  }

  article {
    p {
      margin-top: var(--space-x-small);
      font-size: var(--type-x-small);
      line-height: 1.4;

      &:first-child {
        margin-top: var(--space-medium);
      }
    }
  }

  .read-more {
    font-size: var(--type-x-small);
    display: inline-flex;
    align-items: center;
    gap: var(--space-x-small);
    margin-top: var(--space-medium);
    margin-bottom: var(--space-x-small);
    text-decoration: none;
    font-family: var(--body-font);

    svg {
      height: 0.8em;
      width: auto;
      fill: var(--orange-900);
    }

    &:hover {
      color: var(--green-700);
      transform: translate(1px, 1px);

      svg {
        fill: var(--green-700);
      }
    }
  }
}