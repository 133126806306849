@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Black Italic'), local('WorkSans-BlackItalic'),
        url("../fonts/work-sans/WorkSans-BlackItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-BlackItalic.woff") format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Bold'), local('WorkSans-Bold'),
        url("../fonts/work-sans/WorkSans-Bold.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-Bold.woff") format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Black'), local('WorkSans-Black'),
        url("../fonts/work-sans/WorkSans-Black.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-Black.woff") format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Bold Italic'), local('WorkSans-BoldItalic'),
        url("../fonts/work-sans/WorkSans-BoldItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-BoldItalic.woff") format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraBold'), local('WorkSans-ExtraBold'),
        url("../fonts/work-sans/WorkSans-ExtraBold.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-ExtraBold.woff") format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraLight'), local('WorkSans-ExtraLight'),
        url("../fonts/work-sans/WorkSans-ExtraLight.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-ExtraLight.woff") format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Italic'), local('WorkSans-Italic'),
        url("../fonts/work-sans/WorkSans-Italic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-Italic.woff") format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraLight Italic'), local('WorkSans-ExtraLightItalic'),
        url("../fonts/work-sans/WorkSans-ExtraLightItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-ExtraLightItalic.woff") format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans ExtraBold Italic'), local('WorkSans-ExtraBoldItalic'),
        url("../fonts/work-sans/WorkSans-ExtraBoldItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-ExtraBoldItalic.woff") format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Light Italic'), local('WorkSans-LightItalic'),
        url("../fonts/work-sans/WorkSans-LightItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-LightItalic.woff") format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Medium Italic'), local('WorkSans-MediumItalic'),
        url("../fonts/work-sans/WorkSans-MediumItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-MediumItalic.woff") format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Light'), local('WorkSans-Light'),
        url("../fonts/work-sans/WorkSans-Light.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-Light.woff") format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Medium'), local('WorkSans-Medium'),
        url("../fonts/work-sans/WorkSans-Medium.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-Medium.woff") format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
        url("../fonts/work-sans/WorkSans-SemiBold.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-SemiBold.woff") format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Thin'), local('WorkSans-Thin'),
        url("../fonts/work-sans/WorkSans-Thin.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-Thin.woff") format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Regular'), local('WorkSans-Regular'),
        url("../fonts/work-sans/WorkSans-Regular.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans SemiBold Italic'), local('WorkSans-SemiBoldItalic'),
        url("../fonts/work-sans/WorkSans-SemiBoldItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-SemiBoldItalic.woff") format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans Thin Italic'), local('WorkSans-ThinItalic'),
        url("../fonts/work-sans/WorkSans-ThinItalic.woff2") format('woff2'),
        url("../fonts/work-sans/WorkSans-ThinItalic.woff") format('woff');
    font-weight: 100;
    font-style: italic;
}

