body {
  font-family: var(--body-font);
  background: white;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1 1 auto;
  }

  > footer {
    padding: var(--space-small);
    padding-top: var(--space-medium);

    p {
      text-align: right;
      font-size: var(--type-xx-small);
      font-family: var(--heading-font);

      &:not(:first-child) {
        margin-top: var(--space-xxx-small);
      }
    }

    a {
      text-decoration: none;
    }
  }
}

main {
  margin: var(--space-medium) var(--space-small);
}

.w-full {
  max-width: unset !important;
  width: 100%;
}

ui-spacer {
  display: block;
  flex: 1 1 auto;
}
