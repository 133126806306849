:root {
  --indigo-50:  hsl(216, 100%, 93%);
  --indigo-100: hsl(216, 100%, 85%);
  --indigo-200: hsl(219, 95%, 76%);
  --indigo-300: hsl(222, 81%, 65%);
  --indigo-400: hsl(224, 69%, 54%);
  --indigo-500: hsl(223, 71%, 47%);
  --indigo-600: hsl(228, 74%, 43%);
  --indigo-700: hsl(230, 80%, 38%);
  --indigo-800: hsl(232, 86%, 32%);
  --indigo-900: hsl(234, 90%, 25%);

  --orange-50:  hsl(24, 100%, 93%);
  --orange-100: hsl(22, 100%, 85%);
  --orange-200: hsl(20, 100%, 77%);
  --orange-300: hsl(18, 100%, 70%);
  --orange-400: hsl(16, 94%, 61%);
  --orange-500: hsl(14, 89%, 55%);
  --orange-600: hsl(12, 86%, 47%);
  --orange-700: hsl(10, 93%, 40%);
  --orange-800: hsl(8, 92%, 35%);
  --orange-900: hsl(6, 96%, 26%);

  --grey-50:  hsl(216, 33%, 97%);
  --grey-100: hsl(214, 15%, 91%);
  --grey-200: hsl(210, 16%, 82%);
  --grey-300: hsl(211, 13%, 65%);
  --grey-400: hsl(211, 10%, 53%);
  --grey-500: hsl(211, 12%, 43%);
  --grey-600: hsl(209, 14%, 37%);
  --grey-700: hsl(209, 18%, 30%);
  --grey-800: hsl(209, 20%, 25%);
  --grey-900: hsl(210, 24%, 16%);

  --magenta-50:  hsl(294, 100%, 96%);
  --magenta-100: hsl(294, 97%, 88%);
  --magenta-200: hsl(294, 100%, 78%);
  --magenta-300: hsl(296, 96%, 70%);
  --magenta-400: hsl(300, 82%, 60%);
  --magenta-500: hsl(305, 80%, 49%);
  --magenta-600: hsl(306, 85%, 43%);
  --magenta-700: hsl(306, 88%, 37%);
  --magenta-800: hsl(306, 90%, 31%);
  --magenta-900: hsl(308, 91%, 23%);

  --red-50:  hsl(360, 100%, 95%);
  --red-100: hsl(360, 100%, 87%);
  --red-200: hsl(360, 100%, 80%);
  --red-300: hsl(360, 91%, 69%);
  --red-400: hsl(360, 83%, 62%);
  --red-500: hsl(356, 75%, 53%);
  --red-600: hsl(354, 85%, 44%);
  --red-700: hsl(352, 90%, 35%);
  --red-800: hsl(350, 94%, 28%);
  --red-900: hsl(348, 94%, 20%);

  --yellow-50:  hsl(49, 100%, 96%);
  --yellow-100: hsl(48, 100%, 88%);
  --yellow-200: hsl(48, 95%, 76%);
  --yellow-300: hsl(48, 94%, 68%);
  --yellow-400: hsl(44, 92%, 63%);
  --yellow-500: hsl(42, 87%, 55%);
  --yellow-600: hsl(36, 77%, 49%);
  --yellow-700: hsl(29, 80%, 44%);
  --yellow-800: hsl(22, 82%, 39%);
  --yellow-900: hsl(15, 86%, 30%);

  --green-50:  hsl(125, 65%, 93%);
  --green-100: hsl(127, 65%, 85%);
  --green-200: hsl(124, 63%, 74%);
  --green-300: hsl(123, 53%, 55%);
  --green-400: hsl(123, 57%, 45%);
  --green-500: hsl(122, 73%, 35%);
  --green-600: hsl(122, 80%, 29%);
  --green-700: hsl(125, 79%, 26%);
  --green-800: hsl(125, 86%, 20%);
  --green-900: hsl(125, 97%, 14%);

  --color-text: var(--grey-900);
}