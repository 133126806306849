.post {
  position: relative;

  main {
    margin: var(--space-medium);
  }

  nav a {
    font-family: var(--heading-font);
    display: inline-flex;
    align-items: center;
    gap: var(--space-small);
    font-size: var(--type-x-small);
    color:  var(--indigo-800);
    text-decoration: none;

    &:hover {
      transform: translate(-1px, -1px);
    }

    svg {
      height: 0.8em;
      width: auto;
      fill: var(--indigo-800);
    }

    @media(max-width: 60ch) {
      margin-bottom: var(--space-medium);
    }
  }

  article {
    max-width: 65ch;
    margin: auto;

    header p {
      text-align: left;
      font-size: var(--type-small);
      font-family: var(--heading-font);
      margin-top: var(--space-x-small) !important;
      color: var(--magenta-800);
      line-height: 1.1;
    }

    figure {
      margin: var(--space-large) auto;
      text-align: center;

      img {
        max-width: 90%;
        height: auto;
      }

      figcaption {
        font-size: var(--type-x-small);
        font-style: italic;
        margin-top: var(--space-small);
      }
    }

    p {
      line-height: 1.6;

      &:not(:first-child) {
        margin-top:  var(--space-medium);
      }
    }

    h2 {
      margin-top:  var(--space-medium);
      text-decoration: none;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    ul, ol {
      margin-top: var(--space-medium);
      margin-left: var(--space-large);
      line-height: 1.2;

      li:not(:first-child) {
        margin-top: var(--space-small);
      }
    }

    pre.highlight {
      padding: 16px;
      font-family: var(--code);
      color: white;
      border-radius: 6px;
      margin: var(--space-large) auto;
      overflow-x: scroll;

      code {
        background: unset;
        padding: unset;
        border-radius: unset;
      }
    }
  }

  floating-typewriter {
    --top-offset: 50%;
    --left-offset: -2vw;
    --right-offset: auto;
    --rotation: -12deg;

    svg {
      position: absolute;
      top: var(--top-offset);
      left: var(--left-offset);
      right: var(--right-offset);
      height: calc(3em + 22vw);
      width: auto;
      opacity: 0.25;
      transform: rotate(var(--rotation)) translateY(-50%);

      path {
        fill: var(--yellow-900) !important;
      }
    }

    @media(max-width: 120ch) {
      display: none;
    }
  }
}