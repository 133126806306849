:root {
  --code: 'Source Code Pro', 'Courier Prime', monospace;
  --heading-font:  'Work Sans', sans-serif;
  --body-font: 'Lato', sans-serif;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

h1 {
  font-family: var(--heading-font);
  font-size: var(--type-xxx-large);
  font-weight: 600;
  color: var(--indigo-800);

  @media(max-width: 60ch) {
    font-size: var(--type-xx-large);
  }
}

h2 {
  font-family: var(--heading-font);
  font-size: var(--type-xx-large);
  font-weight: 600;
  color: var(--indigo-800);
  text-decoration: underline;
  text-decoration-color: var(--green-700);

  @media(max-width: 60ch) {
    font-size: var(--type-x-large);
  }
}

h3 {
  font-family: var(--heading-font);
  font-size: var(--type-large);
  color: var(--magenta-800);
}

a {
  color: var(--orange-900);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

code {
  font-family: var(--code);
  background: var(--orange-50);
  padding: 2px 8px;
  border-radius: 4px;
}

p {
  color: var(--color-text);
}

pre {
  line-height: 1.2;
}